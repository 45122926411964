button.primary-btn {
  border: none;
  background-color: #092C4C;
  display: block;
  min-width: 200px;
  border-radius: 8px;
  height: 50px;
  font-weight: bold;
  color: white;
  font-size: 16px;
}

button.secondary-btn {
  border: 1px solid #F2994A;
  background-color: #F2994A;
  display: block;
  min-width: 200px;
  border-radius: 8px;
  height: 50px;
  font-weight: bold;
  color: white;
  font-size: 16px;
}

button.secondary-btn:hover, button.secondary-btn:focus {
  border: 1px solid #F2994A;
  background-color: white;
  display: block;
  min-width: 200px;
  border-radius: 8px;
  height: 50px;
  font-weight: bold;
  color: #F2994A;;
  font-size: 16px;
}


button.primary-btn.outlined {
  border: 1px solid #092C4C;
  color: #092C4C;;
  background-color: white;
}

button.primary-btn:hover {
  border: none;
  background-color: #1D1D1D;
  color: white;
}

button.primary-btn.outlined:hover {
  border: 1px solid #092C4C;
  background-color: #1D1D1D;
  color: white;
}

button.ant-btn-primary {
  border: 1px solid #092C4C;
  background-color: #092C4C;
}

button.ant-btn-primary:hover {
  border: 1px solid #1D1D1D;
  background-color: white;
  color: #1D1D1D;
}
button.ant-btn:not(.secondary-btn):not(.primary-btn):hover {
  border: 1px solid #1D1D1D;
  color: #1D1D1D;
}