.ant-layout-sider-children .logo p {
  color: black;
}

.authsider {
  background-color: white;
}
.logo > img {
  max-width: 100px;
}
.ant-layout-sider-children .logo p {
  color: black;
}

.authsider .ant-layout-sider-children {
  background-color: white;
}

.reach {
  padding-left: 30px;
  left: 4%;
  top: 4%;
  position: absolute;
  background-color: #ffffff00;
  width: 180px;
  height: 50px;
}

.content {
  display: inline-block;
  height: 100px;
  width: 100px;
}

.wallpaper {
  background-image: url("../../images/customer-service.jpg");
  background-size: cover;
  background-repeat: repeat;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.footer {
  display: inline-block;
  opacity: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
  font-family: "Inter";
  color: #e0e0e0;
  left: 0;
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .footer {
    display: none !important;
  }
  .wallpaper {
    overflow: scroll;
  }
}
