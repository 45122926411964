@import url(https://fonts.googleapis.com/css?family=Muli:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap);
* {
  font-family: Inter;
}

.not-found,
.sorry {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 40px;
}

/* override Ant Design with the following syntax: .yourClass.antdClass */

.error.ant-alert {
  margin-bottom: 20px;
}

.form-button.ant-btn {
  width: 100%;
}

/* disable scroll bounce */

html,
body {
  height: 100%;
  overflow: hidden;
}

.app {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

/* nav and main content layout */

.main {
  margin-left: 200px !important;
  background: #fafafa !important;
  height: 100vh !important;
}

.ant-radio-wrapper span {
  white-space: normal;
  white-space: initial;
}

.prefix > p,
.ant-input-group-addon {
  text-transform: uppercase;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #092C4C !important;
    color: white !important;
  }
  
  .ant-select-dropdown-menu-item {
    padding: 9px 12px !important;
  }

  

  .ant-select-selection:hover, .ant-select-focused {
    border-color: #092C4C !important;
  }

  .ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
      color: white;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #092C4C !important;
      border-color: #092C4C !important;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5 !important;
  }

  .ant-layout, .ant-layout-content, footer {
    background: #E0E0E0 !important;
  }

.ant-select-dropdown-menu-item {
  padding: 9px 12px !important;
}

.ant-select-selection:hover,
.ant-select-focused {
  border-color: #092c4c !important;
}

.ant-select-tree
  li
  .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  color: white;
}
.wallpaper .ant-layout-content, .wallpaper footer {
  background-color: transparent !important;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter, .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background-color: rgb(9, 44, 76) !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover, .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: white !important;
}
tbody tr:nth-child(even) td {
  background-color: #F2F2F2;
}
tr th {
  background-color: rgb(9, 44, 76) !important;
  color: white !important;
}
	/*
  	Flaticon icon font: Flaticon
  	Creation date: 21/10/2020 04:25
  	*/

@font-face {
  font-family: "Flaticon";
  src: url(/static/media/Flaticon.56b74cd1.eot);
  src: url(/static/media/Flaticon.56b74cd1.eot?#iefix) format("embedded-opentype"),
       url(/static/media/Flaticon.bd81d11b.woff2) format("woff2"),
       url(/static/media/Flaticon.3c8e0999.woff) format("woff"),
       url(/static/media/Flaticon.d89774a8.ttf) format("truetype"),
       url(/static/media/Flaticon.3284f972.svg#Flaticon) format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url(/static/media/Flaticon.3284f972.svg#Flaticon) format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-supermarket:before { content: "\f100"; }
.flaticon-tag:before { content: "\f101"; }
.flaticon-statistics:before { content: "\f102"; }
.flaticon-share:before { content: "\f103"; }
.flaticon-alert:before { content: "\f104"; }
.flaticon-logout:before { content: "\f105"; }
.flaticon-flag:before { content: "\f106"; }
.flaticon-zoom-in-1:before { content: "\f107"; }
.flaticon-zoom-out:before { content: "\f108"; }
.flaticon-zoom-out-1:before { content: "\f109"; }
.flaticon-zoom-in:before { content: "\f10a"; }
.flaticon-resize:before { content: "\f10b"; }
.flaticon-full-screen:before { content: "\f10c"; }
.flaticon-lightning:before { content: "\f10d"; }
.flaticon-smartphone:before { content: "\f10e"; }
.flaticon-smart-home:before { content: "\f10f"; }
.flaticon-smartphone-1:before { content: "\f110"; }
.flaticon-smart-home-1:before { content: "\f111"; }
.flaticon-lightning-1:before { content: "\f112"; }
.flaticon-wifi:before { content: "\f113"; }
.flaticon-phone-call:before { content: "\f114"; }
.flaticon-television:before { content: "\f115"; }
.flaticon-download-1:before { content: "\f116"; }
.flaticon-television-1:before { content: "\f117"; }
.flaticon-download-2:before { content: "\f118"; }
.flaticon-sad:before { content: "\f119"; }
.flaticon-padlock:before { content: "\f11a"; }
.flaticon-padlock-1:before { content: "\f11b"; }
.flaticon-padlock-2:before { content: "\f11c"; }
.flaticon-reply:before { content: "\f11d"; }
.flaticon-avatar:before { content: "\f11e"; }
.flaticon-download:before { content: "\f11f"; }
.flaticon-speedometer:before { content: "\f120"; }
.flaticon-menu:before { content: "\f121"; }
.flaticon-file:before { content: "\f122"; }
.flaticon-calendar:before { content: "\f123"; }
.flaticon-next:before { content: "\f124"; }
.flaticon-gear:before { content: "\f125"; }
.flaticon-close:before { content: "\f126"; }
.flaticon-tick:before { content: "\f127"; }
.flaticon-file-upload:before { content: "\f128"; }
.flaticon-drawer:before { content: "\f129"; }
.flaticon-draw:before { content: "\f12a"; }
.flaticon-garbage:before { content: "\f12b"; }
.flaticon-close-1:before { content: "\f12c"; }
.nav .sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
}

.logo {
    text-align: center;
    color: white;
    margin: 20px;
    background-color: white;
    padding: 10px;
}

.logo-collapsed {
  text-align: center;
  color: white;
  margin: 20px 0;
}

.nav .options {
    text-align: center;
    margin-bottom: 15px;
}

.logo-enter {
  opacity: 0.01;
}

.logo-enter.logo-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.logo-leave {
  opacity: 1;
}

.logo-leave.logo-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.ant-menu-submenu .ant-menu-item i.anticon.anticon-idcard, .ant-menu-submenu .ant-menu-item i.anticon.anticon-video-camera {
  padding-left: 22px;
}

.sider.navigation .ant-menu-item,.sider.navigation .ant-menu-submenu-title {
    line-height: 60px !important;
    height: 60px !important;
    font-size: 12px !important;
}
.sider.navigation .ant-menu-item .anticon,.sider.navigation .ant-menu-submenu-title .anticon {
    font-size: 22px !important;
    position: relative !important;
    top: 3px !important;
}
.sider.navigation .ant-menu-submenu-arrow {
    right: 0px !important;
    top: calc(50% - 1px) !important
}
.ant-menu-submenu .ant-menu-item i {
  margin-right: 10px;
}

aside .ant-menu {
  background-color: #092C4C !important;
}

aside .ant-menu a, aside .ant-menu .nav-text, aside .ant-menu i {
  color: white !important;
}

aside .ant-menu a:hover * {
  color: #F2994A !important;
}

aside .nav-text {
  font-size: 14px;
  opacity: 0.65 !important;
}

aside .nav-text.active {
  opacity: 1 !important;
}

aside ul {
  padding-top: 60px !important;
}

.ant-menu-inline-collapsed .nav-text {
  display: none !important;
}
.header .ant-menu-horizontal {
  line-height: 65px;
}


.settings .anticon-setting {
  position: relative;
  top: 3px;
  left: 4px;
}

.headerSelect{
  margin-top:7px;
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #092C4C !important;
  border-bottom: 2px solid #092C4C !important;
}
.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
  color: #092C4C !important;
}

.ant-layout-header img.company-logo {
  max-width: 170px;
  height: 30px;
  object-fit: contain;
  position: absolute;
  top: 10px;
  left: 20px;
}

.ant-layout-header .ant-menu-submenu-title span {
  font-weight: 400;
  font-size: 16px;
  color: #092C4C;;
} 

.ant-menu-submenu-title img {
  max-width: 16px;
  height: auto;
  margin-right: 5px;
  margin-bottom: 4px;
}

.ant-menu-submenu-title .anticon-setting svg {
  position: relative;
  bottom: 3px;
}

.ant-menu-submenu-title .anticon-down svg {
  color: #00000038;
  position: relative;
  top: 2px;
  left: 5px;
}
.ant-layout-sider-children .logo p {
  color: black;
}

.authsider {
  background-color: white;
}
.logo > img {
  max-width: 100px;
}
.ant-layout-sider-children .logo p {
  color: black;
}

.authsider .ant-layout-sider-children {
  background-color: white;
}

.reach {
  padding-left: 30px;
  left: 4%;
  top: 4%;
  position: absolute;
  background-color: #ffffff00;
  width: 180px;
  height: 50px;
}

.content {
  display: inline-block;
  height: 100px;
  width: 100px;
}

.wallpaper {
  background-image: url(/static/media/customer-service.82a44ab6.jpg);
  background-size: cover;
  background-repeat: repeat;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.footer {
  display: inline-block;
  opacity: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
  font-family: "Inter";
  color: #e0e0e0;
  left: 0;
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .footer {
    display: none !important;
  }
  .wallpaper {
    overflow: scroll;
  }
}

button.primary-btn {
  border: none;
  background-color: #092C4C;
  display: block;
  min-width: 200px;
  border-radius: 8px;
  height: 50px;
  font-weight: bold;
  color: white;
  font-size: 16px;
}

button.secondary-btn {
  border: 1px solid #F2994A;
  background-color: #F2994A;
  display: block;
  min-width: 200px;
  border-radius: 8px;
  height: 50px;
  font-weight: bold;
  color: white;
  font-size: 16px;
}

button.secondary-btn:hover, button.secondary-btn:focus {
  border: 1px solid #F2994A;
  background-color: white;
  display: block;
  min-width: 200px;
  border-radius: 8px;
  height: 50px;
  font-weight: bold;
  color: #F2994A;;
  font-size: 16px;
}


button.primary-btn.outlined {
  border: 1px solid #092C4C;
  color: #092C4C;;
  background-color: white;
}

button.primary-btn:hover {
  border: none;
  background-color: #1D1D1D;
  color: white;
}

button.primary-btn.outlined:hover {
  border: 1px solid #092C4C;
  background-color: #1D1D1D;
  color: white;
}

button.ant-btn-primary {
  border: 1px solid #092C4C;
  background-color: #092C4C;
}

button.ant-btn-primary:hover {
  border: 1px solid #1D1D1D;
  background-color: white;
  color: #1D1D1D;
}
button.ant-btn:not(.secondary-btn):not(.primary-btn):hover {
  border: 1px solid #1D1D1D;
  color: #1D1D1D;
}
.energy-customerform .ant-form-item{margin-bottom:10px !important}.energy-customerform input{height:40px !important}.energy-customerform .ant-select{height:40px}.energy-customerform .ant-select .ant-select-selection{height:40px}.energy-customerform .ant-select .ant-select-selection__rendered{line-height:40px}

* {
  font-family: Inter;
}

*:not(aside *):not(thead *) {
  font-size: 16px;
}

.ant-form label {
  font-size: 16px;
}

h1.header {
  font-size: 32px;
  color: #092C4C;
  font-weight: bold;
  margin-bottom: 0px;
}
