.energy-customerform {
  .ant-form-item {
    margin-bottom: 10px !important;
  }
  input {
    height: 40px !important;
  }
  .ant-select {
    height: 40px;
    .ant-select-selection {
      height: 40px;
    }
    .ant-select-selection__rendered {
      line-height: 40px;
    }
  }
}