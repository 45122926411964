.header .ant-menu-horizontal {
  line-height: 65px;
}


.settings .anticon-setting {
  position: relative;
  top: 3px;
  left: 4px;
}

.headerSelect{
  margin-top:7px;
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #092C4C !important;
  border-bottom: 2px solid #092C4C !important;
}
.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
  color: #092C4C !important;
}

.ant-layout-header img.company-logo {
  max-width: 170px;
  height: 30px;
  object-fit: contain;
  position: absolute;
  top: 10px;
  left: 20px;
}

.ant-layout-header .ant-menu-submenu-title span {
  font-weight: 400;
  font-size: 16px;
  color: #092C4C;;
} 

.ant-menu-submenu-title img {
  max-width: 16px;
  height: auto;
  margin-right: 5px;
  margin-bottom: 4px;
}

.ant-menu-submenu-title .anticon-setting svg {
  position: relative;
  bottom: 3px;
}

.ant-menu-submenu-title .anticon-down svg {
  color: #00000038;
  position: relative;
  top: 2px;
  left: 5px;
}