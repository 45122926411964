.nav .sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
}

.logo {
    text-align: center;
    color: white;
    margin: 20px;
    background-color: white;
    padding: 10px;
}

.logo-collapsed {
  text-align: center;
  color: white;
  margin: 20px 0;
}

.nav .options {
    text-align: center;
    margin-bottom: 15px;
}

.logo-enter {
  opacity: 0.01;
}

.logo-enter.logo-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.logo-leave {
  opacity: 1;
}

.logo-leave.logo-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.ant-menu-submenu .ant-menu-item i.anticon.anticon-idcard, .ant-menu-submenu .ant-menu-item i.anticon.anticon-video-camera {
  padding-left: 22px;
}

.sider.navigation .ant-menu-item,.sider.navigation .ant-menu-submenu-title {
    line-height: 60px !important;
    height: 60px !important;
    font-size: 12px !important;
}
.sider.navigation .ant-menu-item .anticon,.sider.navigation .ant-menu-submenu-title .anticon {
    font-size: 22px !important;
    position: relative !important;
    top: 3px !important;
}
.sider.navigation .ant-menu-submenu-arrow {
    right: 0px !important;
    top: calc(50% - 1px) !important
}
.ant-menu-submenu .ant-menu-item i {
  margin-right: 10px;
}

aside .ant-menu {
  background-color: #092C4C !important;
}

aside .ant-menu a, aside .ant-menu .nav-text, aside .ant-menu i {
  color: white !important;
}

aside .ant-menu a:hover * {
  color: #F2994A !important;
}

aside .nav-text {
  font-size: 14px;
  opacity: 0.65 !important;
}

aside .nav-text.active {
  opacity: 1 !important;
}

aside ul {
  padding-top: 60px !important;
}

.ant-menu-inline-collapsed .nav-text {
  display: none !important;
}