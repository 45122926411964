@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

* {
  font-family: Inter;
}

*:not(aside *):not(thead *) {
  font-size: 16px;
}

.ant-form label {
  font-size: 16px;
}

h1.header {
  font-size: 32px;
  color: #092C4C;
  font-weight: bold;
  margin-bottom: 0px;
}